<script lang="ts" setup>
import { ref } from "vue";
import { Icons } from "@goodvibes/kudoslink";

defineProps<{
  modelValue?: string;
  placeholder?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "search", value?: string): void;
  (e: "blur", event: FocusEvent): void;
}>();

const inputEl = ref<HTMLInputElement>();

defineExpose({ focus });

function focus() {
  inputEl.value?.focus();
}
</script>

<template>
    <div class="relative">
        <input ref="inputEl" :value="modelValue" @input="
            emit('update:modelValue', ($event.target as HTMLInputElement).value)
            " @blur="emit('blur', $event)" :placeholder="placeholder" type="text"
            class="focus:outline-none bg-white leading-[64px] h-14 py-0 pl-5 pr-12 w-full rounded-xl font-sans text-[#3F4A55] placeholder:text-[#BBC4CD] [box-shadow:0px_74px_30px_rgba(177,195,213,0.02),0px_42px_25px_rgba(177,195,213,0.08),0px_19px_19px_rgba(177,195,213,0.14),0px_-2px_10px_rgba(177,195,213,0.17),0px_0px_0px_rgba(177,195,213,0.1)]" />
        <Icons.MagnifierIcon @click="emit('search', modelValue)"
            class="absolute h-5 right-5 top-1/2 -translate-y-1/2 leading-[1rem] fill-[#BBC4CD] cursor-pointer" />
    </div>
</template>
